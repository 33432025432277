import {fetchUtils} from 'react-admin';
import {stringify} from "query-string";
import moment from "moment";
const fetchJson = (url, options = {}) => {
    const api = process.env.REACT_APP_MATESAPI // 'http://localhost:40018/api'||
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json',Authorization: 'Bearer ' + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjY2ZGU2NmM1M2VkMTljZDk1MTllMjFhNSIsInVzZXJOYW1lIjoiZGFtYWJlbiIsImNvbXBhbnkiOiJDRFRBIiwibWVtYmVySWQiOiI2NmM4OGJmZWE4NTA0NGM0NjVjZmE3N2YiLCJyb2xlcyI6WyJtZW1iZXIiXX0sImlhdCI6MTcyNzA5MzExMX0.2om1NEC5_E9y_CnNiyVzFTm_OXgUzkhy4LDA2FbCv0k' });
    }
    let query = ''
    if(options.query)
    {
        query = "?" + stringify(options.query)
    }
    // add your own headers here
    // const token = localStorage.getItem('token');
    //options.headers.set('Authorization', `Bearer ${token}`);
   // options.headers.set('x-company', localStorage.getItem('companyMateId').toUpperCase());
    return fetchUtils.fetchJson(api+url+query, options);
}


export function getMonthlyOTHours({date}) {
    return fetchJson('/otSummery', {
        query:{
            startDate: moment(date).startOf('month').format('MM-DD-YYYY')
        }
    }).then(_a => {
        let json =_a.json;
        return {data:json}
    })
}


export function getMonthlyTeamSummery({date}) {
    return fetchJson('/teamSummery', {
        query:{
            startDate: moment(date).startOf('month').format('DD-MM-YYYY')
        }
    }).then(_a => {
        let json =_a.json;
        return {data:json}
    })
}


export function getTimeSheetSummery({atDate, month, periodStart, periodEnd}) {
    return fetchJson('/timesheet', {
        query:{
            atDate: moment(atDate).format('YYYY-MM-DD'),
            startDate: periodStart||moment(month).startOf('month').format('YYYY-MM-DD'),
            endDate: periodEnd||moment(month).endOf('month').format('YYYY-MM-DD')
        }
    }).then(_a => {
        let json =_a.json;
        return {data:json}
    })
}

export function payrollMembers({startDate, endDate}) {
    return fetchJson('/payrollMembers', {
        query:{
            startDate:startDate,
            endDate: endDate
        }
    }).then(_a => {
        let json =_a.json;
        return {data:json}
    })
}

export function acknowledgeChanges(newMembers,inactiveMembers, updatedEmployees) {
   // console.log(newMembers,inactiveMembers)
    return fetchJson('/acknowledgePayroll', {
        method: 'POST',
        body: JSON.stringify({newMembers,inactiveMembers, updatedEmployees}),
    }).then(_a => {
        let json =_a.json;
        return {data:json}
    })
}
