const initialState = {
   newHires: [],
   endEmployments: []

};

export default (previousState = initialState, { type, payload }) => {
    if (type === 'EMPLOYEE_VARIANCE_LOADED') {
        const {newHires, endEmployments} = payload
        return {...previousState, newHires, endEmployments};
    }

    return previousState;
}
