import {call, put, takeLatest} from 'redux-saga/effects';
import {fetchEnd, fetchStart, refreshView, showNotification} from 'react-admin';
import OdataServiceProvider from "../Helpers/odataServiceProvider";
import _ from "lodash";
import moment from "moment";
export default function* reportSaga() {
    yield takeLatest('GET_EMPLOYEE_VARIANCE', function* (action) {
            const {payload} = action

        yield put(fetchStart());
        try {
            const payrollRuns = _.orderBy(payload.payrollRuns,['payrollDate'],['ASC'])
            const {data:payrolls} = yield  call(OdataServiceProvider.getList,'payrolls', { filter:{filter:`payrollRunId in (${payrollRuns.map(x => "'" + x.id + "'").toString()})`,expand:'payrollRun'},  })
            const {data:employees} = yield  call(OdataServiceProvider.getList,'employees', { filter:{} })


            // console.log({date:payrollRuns,payload})
            const emps = _.groupBy(_.orderBy(payrolls,['payrollRun.payrollDate'],['ASC']),'employeeId')

            const newHires = []
            const endEmployments = []
            // const lookupPayrollRuns = _.groupBy(payrollRuns,'id')
            const lookupEmployees = _.groupBy(employees,'id')
            // console.log({lookupPayrollRuns,lookupEmployees})
            for (const key in emps) {
                const e = emps[key]

                if(e.length<payrollRuns.length)
                {

                     // const payrollRunStart = lookupPayrollRuns[e[0].payrollRunId][0]
                    const payrollRunStart = e[0].payrollRun
                    if(moment(payrollRunStart.payrollDate)>moment(payrollRuns[0].payrollDate)) {
                        e[0].employee = lookupEmployees[e[0].employeeId][0]
                        newHires.push(e[0])
                    }


                    // const payrollRunEnd = lookupPayrollRuns[e[e.length-1].payrollRunId][0]
                    const payrollRunEnd = e[e.length-1].payrollRun
                    if(moment(payrollRunEnd.payrollDate)<moment(payrollRuns[payrollRuns.length-1].payrollDate)) {
                        // console.log(lookupPayrollRuns[e[e.length-1].payrollRunId])
                        // console.log(payrollRunEnd.payrollDate)
                        e[e.length-1].employee = lookupEmployees[e[e.length-1].employeeId][0]
                        endEmployments.push(e[e.length-1])
                    }

                }
            }
            console.log({emps,newHires, endEmployments})


            yield put({
                type: 'EMPLOYEE_VARIANCE_LOADED',
                payload: {newHires:_.orderBy(newHires,['payrollRun.payrollDate'],['desc']),endEmployments:_.orderBy(endEmployments,['payrollRun.payrollDate'],['desc'])},
            });

            yield put(fetchEnd());
        }
        catch (e){
            console.log(e)
            yield put(fetchEnd());

        }



        }
    )


}
